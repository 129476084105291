
import { IStatusPanel, IStatusPanelParams } from 'ag-grid-enterprise'
import { Component, Vue } from 'vue-property-decorator'
@Component
export default class TableSizeSelect extends Vue implements IStatusPanel {
  PaginationItems = [{ text: 'All', value: 999999 }, 15, 25, 50, 100]
  params!: IStatusPanelParams
  refresh (): boolean {
    return false
  }
}
