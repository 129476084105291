import * as Sentry from '@sentry/vue'
import ky from 'ky'

import { type PagePurchaseOrder } from '@/client/purchasing'
import { i18n } from '@/plugins/i18n'
import store from '@/store'
import type { IToken } from '@/types/app'
// import { formatUnixDate } from '@/utils/date'

const baseUrl = process.env?.VUE_APP_BASE_URL

export type PurchaseOrdersApiInterface = {
  getPurchaseOrders: (token: IToken, opts: { userId: number, page?: number, size?: number, search?: string, offset?: number, sort?: Array<any>}) => Promise<PagePurchaseOrder>
  getPODocumentById: (token: IToken, userId: number, id:string) => Promise<any>
}

const sortable = (url: string, sort: Array<any> | null): any => {
  // console.log(sort)
  if (sort) {
    sort.forEach((option) => {
      url += `&sort=${option.colId},${option.sort}`
    })
  }
  return url
}
const purchaseOrderUrls = {
  document: (id: string, userId: number) => `${baseUrl}documents/${userId}/${id}/download`
}
export default {
  async getPurchaseOrders (token: IToken, opts: { userId: number, page?: number, size?: number, search?: string, offset?: number, sort?: Array<any>}): Promise<PagePurchaseOrder|null> {
    const { userId, size, page, search, offset, sort } = opts
    const requestOpts = {
      headers: { Authorization: `Bearer ${token}` }
    }
    let url = `${baseUrl}purchasing/${userId}`
    if (size) url = `${url}?size=${size}`
    if (search) url = `${url}&search=${search}`
    if (offset) url = `${url}&offset=${offset}`
    if (sort) url = sortable(url, sort)
    if (typeof page !== 'undefined') url = `${url}&page=${page - 1}`
    try {
      return await ky.get(url, requestOpts).json()
    } catch (e) {
      Sentry.captureException(e)
      await store.dispatch(
        'toasts/addError',
        i18n.t('pages.errors.generic.get', { entityName: 'Purchase Orders' }),
        { root: true }
      )
      return Promise.resolve(null)
    }
  },
  async getPODocumentById (token: IToken, userId: number, id: string): Promise<any> {
    try {
      const response = await ky.get(purchaseOrderUrls.document(id, userId), {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const blob = await response.blob()
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      const pdf = await new Promise((resolve) => {
        reader.onloadend = function () {
          resolve(reader.result)
        }
      })
      return pdf
    } catch (e) {
      await store.dispatch(
        'toasts/addError',
        i18n.t('pages.errors.generic.get', { entityName: 'invoice document' }),
        { root: true }
      )
    }
  }
} as PurchaseOrdersApiInterface
