import type { RouteConfig } from 'vue-router'

import { ensureToken } from '@/router/guards/ensureToken'

export default [
  {
    path: '/invoices-purchase-orders',
    component: () =>
      import(
        /* webpackChunkName: "invoices-purchase-views" */ './views/Listing.vue'
      ),
    props: true,
    meta: {
      management: true
    },
    beforeEnter: ensureToken,
    children: [
      {
        path: 'invoices',
        name: 'invoices',
        component: () =>
          import(
            /* webpackChunkName: "invoices-purchase-views" */ './modules/invoices/views/Invoices.vue'
          )
      },
      {
        path: 'purchase-orders',
        name: 'purchaseOrders',
        meta: {
          isPoAndInventoryEnabled: true
        },
        component: () =>
          import(
            /* webpackChunkName: "invoices-purchase-views" */ './modules/purchase-order/views/PurchaseOrders.vue'
          )
      }
    ]
  },
  {
    path: '/invoices/add',
    name: 'addInvoice',
    meta: {
      management: true
    },
    component: () =>
      import(
        /* webpackChunkName: "invoice-views" */ './modules/invoices/views/InvoiceUpload.vue'
      ),
    beforeEnter: ensureToken
  },
  {
    path: '/invoices/:id',
    name: 'invoice',
    component: () =>
      import(
        /* webpackChunkName: "invoice-views" */ './modules/invoices/views/InvoiceDetails.vue'
      ),
    props: true,
    beforeEnter: ensureToken,
    meta: {
      management: true
    }
  },
  {
    path: '/purchase-order/draft',
    name: 'newPurchaseOrders',
    component: () =>
      import(
        /* webpackChunkName: "purchase-views" */ './modules/purchase-order/views/PurchaseOrdersDetails.vue'
      ),
    props: true,
    beforeEnter: ensureToken,
    meta: {
      management: true,
      isPoAndInventoryEnabled: true
    }
  },
  {
    path: '/purchase-order/:id',
    props: true,
    beforeEnter: ensureToken,
    meta: {
      management: true,
      isPoAndInventoryEnabled: true
    },
    name: 'purchase-order',
    component: () =>
      import(
        /* webpackChunkName: "purchase-views" */ './modules/purchase-order/views/PurchaseOrdersDetails.vue'
      )
  },
  {
    path: '/purchase-order/:id/generate',
    name: 'purchaseOrdersGenerate',
    meta: {
      management: true,
      isPoAndInventoryEnabled: true
    },
    beforeEnter: ensureToken,
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "purchase-views" */ './modules/purchase-order/views/PurchaseOrderGenerate.vue'
      )
  },
  {
    path: '/purchase-orders/:id/:documentId/send',
    name: 'purchaseOrderSend',
    component: () => import(/* webpackChunkName: "purchase-views" */ './modules/purchase-order/views/PurchaseOrderSend.vue'),
    props: true,
    beforeEnter: ensureToken,
    meta: {
      management: true,
      isPoAndInventoryEnabled: true
    }
  }
] as Array<RouteConfig>
